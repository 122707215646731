import { useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { buttonVariants } from './ui/button'
import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'

const frequencies = [
  { value: 'monthly', label: 'monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'annually', priceSuffix: '/year' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[0])
  const { t } = useTranslation()

  const tiers = [
    {
      name: t('starter_plan'),
      id: 'tier-starter',
      href: '#',
      price: { monthly: '€0', annually: '€0' },
      description: t('starter_plan_description'),
      features: [t('free_microsite'), t('custom_branding'), t('analytics_dashboard')],
      mostPopular: false,
    },
    {
      name: t('plus_plan'),
      id: 'tier-plus',
      href: '#',
      price: { monthly: '€29.90', annually: '€299.90' },
      description: t('plus_plan_description'),
      features: [
        t('free_microsite'),
        t('custom_branding'),
        t('analytics_dashboard'),
        t('lead_widgets'),
      ],
      mostPopular: true,
    },
    {
      name: t('business_plan'),
      id: 'tier-business',
      href: '#',
      price: { monthly: '€99', annually: '€999' },
      description: t('business_plan_description'),
      features: [
        t('free_microsite'),
        t('custom_branding'),
        t('analytics_dashboard'),
        t('lead_widgets'),
        t('business_support'),
        t('proplink_marketing_pixel'),
        t('unlimited_seller_leads'),
      ],
      mostPopular: false,
    },
  ]

  return (
    <div className="bg-background py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-primary/60">
            {t('pricing')}
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-primary sm:text-5xl">
            {t('pricing_plans_title')}
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-primary/70">
          {t('pricing_plans_description')}
        </p>
        <div className="mt-16 flex justify-center">
          <fieldset aria-label={t('payment_frequency')}>
            <RadioGroup
              value={frequency}
              defaultValue={frequencies[0]}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-primary/40">
              {frequencies.map((option) => (
                <Radio
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? 'bg-[#6A94E6] text-white' : 'text-primary',
                      'cursor-pointer rounded-full px-2.5 py-1',
                    )
                  }>
                  {t(option.label)}
                </Radio>
              ))}
            </RadioGroup>
          </fieldset>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? 'bg-[#EDF1F8] shadow ring-primary/60'
                  : 'ring-1 ring-primary/20',
                'rounded-3xl p-8 xl:p-10',
              )}>
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-primary' : 'text-primary',
                    'text-lg font-semibold leading-8',
                  )}>
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-[#6A94E6] px-2.5 py-1 text-xs font-semibold leading-5 text-primary-foreground">
                    {t('most_popular')}
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-primary/60">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-primary">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-primary/60">
                  {t(frequency.priceSuffix)}
                </span>
              </p>
              <Link
                aria-describedby={tier.id}
                to={tier.href}
                className={buttonVariants({
                  size: 'lg',
                  variant: !tier.mostPopular ? 'outline' : 'default',
                  className: !tier.mostPopular
                    ? 'mt-6 w-full'
                    : 'mt-6 w-full bg-[#6A94E6]',
                })}>
                {t('buy_plan')}
              </Link>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-primary/60"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
